body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Mulish';
  src: local('Mulish'), url(./assets/fonts/Mulish-ExtraLight.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: local('Mulish'), url(./assets/fonts/Mulish-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: local('Mulish'), url(./assets/fonts/Mulish-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: local('Mulish'), url(./assets/fonts/Mulish-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  line-height: 150%;
  letter-spacing: -0.02em;
}
input {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  line-height: 150%;
  letter-spacing: -0.02em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

::-webkit-scrollbar {
  display: none;
}
